

@media print {
  // @page { size: landscape; }

  html, body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  html {
    background-color: #F4F4F4 !important;
  }

  .no-print {
    display: none !important;
  }

  .box {
    background-color: white !important;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1) !important;
  }

  ._g-tabs.tabs.primary.is-toggle li.is-active a {
    background-color: #E11383 !important;
  }

  #content {
    border: none;
  }

  .ngx-datatable.material .datatable-header .datatable-header-cell.is-table-header {
    background-color: #848EA0 !important;
  }

  .select select, .input, .textarea  {
    background-color: white !important;
  }
}
