// Font Family
$family-sans-serif: "Helvetica Neue", "Helvetica", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Arial", sans-serif;
  
// $variable-columns
$variable-columns: false;

// Root element size to calculate rem (in pixel) 
$body-size: 10px;
$body-size-mobile: 9px;

$column-gap: $block-spacing / 2;
$column-gap-mobile: $block-spacing-mobile / 2;

$tile-spacing: $block-spacing / 2;
$tile-spacing-mobile: $block-spacing-mobile / 2;

$field-spacing: $block-spacing;
$field-spacing-mobile: $block-spacing-mobile;
$field-horizontal-spacing: $brick-spacing;
$field-horizontal-spacing-mobile: $brick-spacing;

$label-spacing: $brick-spacing;

$title-size: 3rem;
$hero-body-padding: 4rem 2rem;
$dropdown-menu-min-width: 16rem;

// The section horizontal gap for desktop (in pixel)
$section-gap: 80px;
$section-gap-mini-bar: 16px;

// The section horizontal gap for mobile (in pixel)
$section-gap-mobile: 16px;

// Override Bulma's section-padding
$section-padding-fit: 0 $section-gap;
$section-padding-small: $block-spacing $section-gap;
$section-padding: ($block-spacing * 2) $section-gap;
$section-padding-medium: ($block-spacing * 3) $section-gap; // desktop only
$section-padding-large: ($block-spacing * 6) $section-gap; // desktop only

// The container horizontal gap for desktop, which acts as the offset for breakpoints
// In another word, this is minimum overall gap for desktop. It will be increase when window width > container's width + $gap
// Should be >= section gap (in pixel)
$gap: $section-gap;
// Remark: The container has no horizontal gap for mobile by design

$control-icon-width: 3rem;

// =================================================================================
// ===== Bulma Variables v0.9.0 =====

// = generic (base > generic)
// $body-background-color: $scheme-main;

$hr-background-color: #d1d1d1;
$hr-height: 1px;
// $hr-margin: 2.1rem 0;
// --

// = controls (utilities > controls)
// $control-radius: $radius;
// $control-radius-small: $radius-small;

// $control-border-width: 1px;

// $control-padding-vertical: calc(0.5em - #{$control-border-width});
// $control-padding-horizontal: calc(0.75em - #{$control-border-width});
// --

// = label (form > tools)
$label-color: $text-strong;
$label-weight: $weight-bold;

// $help-size: $size-small;
// --

// = input (form > shared)
$input-color: $text;
// $input-background-color: $scheme-main;
// $input-border-color: $border;
// $input-height: $control-height;
$input-shadow: inset 0 0.0625em 0.125em rgba($scheme-invert, 0.05);
$input-placeholder-color: $text-placeholder;

$input-hover-color: invalid;
$input-hover-border-color: invalid;

$input-focus-color: invalid;
$input-focus-border-color: invalid;
$input-focus-box-shadow-size: invalid;
$input-focus-box-shadow-color: invalid;

$input-disabled-color: $text-disabled;
// $input-disabled-background-color: $background;
// $input-disabled-border-color: $background;
$input-disabled-placeholder-color: invalid;

$input-arrow: $text;

$input-icon-color: $text;
// $input-icon-active-color: $text;

// $input-radius: $radius;
// --

// == Element ==

// = button
// $button-color: $text-strong;
// $button-background-color: $scheme-main;
// $button-family: false;

// $button-border-color: $border;
// $button-border-width: $control-border-width;

$button-padding-vertical: 0;
$button-padding-horizontal: 1em;

$button-hover-color: invalid;
$button-hover-border-color: invalid;

$button-focus-color: invalid;
$button-focus-border-color: invalid;
$button-focus-box-shadow-size: invalid;
$button-focus-box-shadow-color: invalid;

$button-active-color: invalid;
$button-active-border-color: invalid;

// $button-text-color: $text;
// $button-text-hover-background-color: $background;
// $button-text-hover-color: $text-strong;

// $button-disabled-background-color: $scheme-main;
// $button-disabled-border-color: $border;
// $button-disabled-shadow: none;
// $button-disabled-opacity: 0.5;

// $button-static-color: $text-light;
// $button-static-background-color: $scheme-main-ter;
// $button-static-border-color: $border;
// --

// = box
// $box-color: $text;
// $box-background-color: $scheme-main;
$box-radius: 0;
$box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0px 0 1px rgba($scheme-invert, 0.02);
$box-shadow: 0 2px 3px rgba($scheme-invert, 0.1), 0 0 0 1px rgba($scheme-invert, 0.1);
$box-shadow-hovered: 1px 3px 10px 2px rgba($scheme-invert, 0.1), 0 0 0 1px rgba($scheme-invert, 0.1);
$box-padding: 1.75rem;

// $box-link-hover-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0 0 1px $link;
// $box-link-active-shadow: inset 0 1px 2px rgba($scheme-invert, 0.2), 0 0 0 1px $link;
// --

// = icon
$icon-text-spacing: $brick-spacing;

// = table
// $table-color: $text-strong;
// $table-background-color: $scheme-main;

// $table-cell-border: 1px solid $border;
// $table-cell-border-width: 0 0 1px;
// $table-cell-padding: 0.5em 0.75em;
// $table-cell-heading-color: $text-strong;

// $table-head-cell-border-width: 0 0 2px;
$table-head-cell-color: $table-header-invert;
// $table-foot-cell-border-width: 2px 0 0;
// $table-foot-cell-color: $text-strong;

$table-head-background-color: $table-header;
// $table-body-background-color: transparent;
// $table-foot-background-color: transparent;

// $table-row-hover-background-color: $scheme-main-bis;

// $table-row-active-background-color: $primary;
// $table-row-active-color: $primary-invert;
// --

$table-striped-row-even-background-color: $scheme-main-bis;
$table-striped-row-even-hover-background-color: $scheme-main-ter;

// = progress
// $progress-bar-background-color: $border-light;
// $progress-value-background-color: $text;
// $progress-border-radius: $radius-rounded;

// $progress-indeterminate-duration: 1.5s;
// --

// == Component ==

// = breadcrumb
// $breadcrumb-item-color: $link;
// $breadcrumb-item-hover-color: $link-hover;
// $breadcrumb-item-active-color: $text-strong;

// $breadcrumb-item-padding-vertical: 0;
// $breadcrumb-item-padding-horizontal: 0.75em;

// $breadcrumb-item-separator-color: $border-hover;
// --

// = list
// $list-background-color: $scheme-main;
// $list-shadow: 0 2px 3px rgba($scheme-invert, 0.1), 0 0 0 1px rgba($scheme-invert, 0.1);
// $list-radius: $radius;

// $list-item-border: 1px solid $border;
// $list-item-color: $text;
// $list-item-active-background-color: $link;
// $list-item-active-color: $link-invert;
// $list-item-hover-background-color: $background;
// --

// = side menu (menu)
// $menu-item-color: $text;
// $menu-item-radius: $radius-small;
// $menu-item-hover-color: $text-strong;
// $menu-item-hover-background-color: $background;
// $menu-item-active-color: $link-invert;
// $menu-item-active-background-color: $link;

// $menu-list-border-left: 1px solid $border;
// $menu-list-line-height: 1.25;
// $menu-list-link-padding: 0.5em 0.75em;
// $menu-nested-list-margin: 0.75em;
// $menu-nested-list-padding-left: 0.75em;

// $menu-label-color: $text-light;
// $menu-label-font-size: 0.75em;
// $menu-label-letter-spacing: 0.1em;
// $menu-label-spacing: 1em;
// --

// = message
$message-radius: 0;
$message-header-padding: 2.8rem;
$message-header-radius: 0;
$message-body-radius: 0;

// = modal
$modal-z: 1020;

// $modal-background-background-color: rgba($scheme-invert, 0.86);

// $modal-content-margin-mobile: 20px;
// $modal-content-spacing-mobile: 160px;
// $modal-content-spacing-tablet: 40px;

$modal-close-dimensions: 20px;
// $modal-close-right: 20px;
// $modal-close-top: 20px;

// $modal-card-spacing: 40px;

$modal-card-head-background-color: $primary;
// $modal-card-head-border-bottom: 1px solid $border;
$modal-card-head-padding: 2.1rem;
$modal-card-head-radius: 0;

$modal-card-title-color: $primary-invert;
// $modal-card-title-line-height: 1;
$modal-card-title-size: $size-medium;

$modal-card-foot-radius: 0;
// $modal-card-foot-border-top: 1px solid $border;

// $modal-card-body-background-color: $scheme-main;
$modal-card-body-padding: 2rem;
// --

// = navbar
// $navbar-background-color: $scheme-main;
// $navbar-box-shadow-size: 0 2px 0 0;
// $navbar-box-shadow-color: $background;
// $navbar-box-shadow-size: invalid-size; // make invalid to use our .has-shadow
// $navbar-box-shadow-color: invalid-color; // make invalid to use our .has-shadow
$navbar-height: 4rem;
// $navbar-padding-vertical: 1rem;
// $navbar-padding-horizontal: 2rem;
// $navbar-z: 30;
// $navbar-fixed-z: 30;

// $navbar-item-color: $text;
$navbar-item-hover-color: $primary;
$navbar-item-hover-background-color: inherit;
// $navbar-item-active-color: $scheme-invert;
// $navbar-item-active-background-color: transparent;
$navbar-item-img-max-height: none;

// $navbar-burger-color: $navbar-item-color;

// $navbar-tab-hover-background-color: transparent;
// $navbar-tab-hover-border-bottom-color: $link;
// $navbar-tab-active-color: $link;
// $navbar-tab-active-background-color: transparent;
// $navbar-tab-active-border-bottom-color: $link;
// $navbar-tab-active-border-bottom-style: solid;
// $navbar-tab-active-border-bottom-width: 3px;

// $navbar-dropdown-background-color: $scheme-main;
// $navbar-dropdown-border-top: 2px solid $border;
// $navbar-dropdown-offset: -4px;
$navbar-dropdown-arrow: $text;
// $navbar-dropdown-radius: $radius-large;
// $navbar-dropdown-z: 20;

// $navbar-dropdown-boxed-radius: $radius-large;
// $navbar-dropdown-boxed-shadow: 0 8px 8px rgba($scheme-invert, 0.1), 0 0 0 1px rgba($scheme-invert, 0.1);

// $navbar-dropdown-item-hover-color: $scheme-invert;
// $navbar-dropdown-item-hover-background-color: $background;
// $navbar-dropdown-item-active-color: $link;
// $navbar-dropdown-item-active-background-color: $background;

// $navbar-divider-background-color: $background;
// $navbar-divider-height: 2px;

// $navbar-bottom-box-shadow-size: 0 -2px 0 0;

// --

// = pagination
// $pagination-color: $text-strong;
// $pagination-border-color: $border;
// $pagination-margin: -0.25rem;
// $pagination-min-width: $control-height;

// $pagination-item-font-size: 1em;
// $pagination-item-margin: 0.25rem;
// $pagination-item-padding-left: 0.5em;
// $pagination-item-padding-right: 0.5em;

// $pagination-hover-color: $link-hover;
// $pagination-hover-border-color: $link-hover-border;

// $pagination-focus-color: $link-focus;
// $pagination-focus-border-color: $link-focus-border;

// $pagination-active-color: $link-active;
// $pagination-active-border-color: $link-active-border;

$pagination-disabled-color: $text-disabled;
// $pagination-disabled-background-color: $border;
// $pagination-disabled-border-color: $border;

$pagination-current-color: findColorInvert($secondary);
$pagination-current-background-color: $secondary;
$pagination-current-border-color: $border;

// $pagination-ellipsis-color: $grey-light;

// $pagination-shadow-inset: inset 0 1px 2px rgba($scheme-invert, 0.2);
// --

// = tabs
// $tabs-border-bottom-color: $border;
// $tabs-border-bottom-style: solid;
// $tabs-border-bottom-width: 1px;
// $tabs-link-color: $text;
// $tabs-link-hover-border-bottom-color: $text-strong;
// $tabs-link-hover-color: $text-strong;
// $tabs-link-active-border-bottom-color: $link;
// $tabs-link-active-color: $link;
// $tabs-link-padding: 0.5em 1em;

// $tabs-boxed-link-radius: $radius;
// $tabs-boxed-link-hover-background-color: $background;
// $tabs-boxed-link-hover-border-bottom-color: $border;

// $tabs-boxed-link-active-background-color: $scheme-main;
// $tabs-boxed-link-active-border-color: $border;
// $tabs-boxed-link-active-border-bottom-color: transparent;

// $tabs-toggle-link-border-color: $border;
// $tabs-toggle-link-border-style: solid;
// $tabs-toggle-link-border-width: 1px;
// $tabs-toggle-link-hover-background-color: $background;
// $tabs-toggle-link-hover-border-color: $border-hover;
// $tabs-toggle-link-radius: $radius;
// $tabs-toggle-link-active-background-color: $link;
// $tabs-toggle-link-active-border-color: $link;
// $tabs-toggle-link-active-color: $link-invert;
// --


// =================================================================================
// ===== Trunks Variables v0.4.0 =====

// = base > helpers
// $box-shadow-for-has-shadow: 0 2px 3px rgba($scheme-invert, 0.1), 0 0 0 1px rgba($scheme-invert, 0.1);
// $box-shadow-for-has-shadow-hovered: 1px 3px 10px 2px rgba($scheme-invert, 0.1), 0 0 0 1px rgba($scheme-invert, 0.1);
// --

// = element
// $badge-box-shadow: 0 0 0 .2rem #fff;
// $badge-font-size: $size-normal;
// $badge-border-radius: 100%;

// $badge-corner-font-size: $size-normal;
// $badge-corner-background-color: $grey;
// $badge-corner-box-shadow: 0 0 0 .2rem #fff;
// $badge-corner-border-radius: 1rem;

// $box-hightlight-color: $primary;

$link-decoration: underline;

$label-size: $size-normal;
$input-arrow-size: 0.6em;
$input-arrow-right: 0.6em;
$input-arrow-border-width: 1px;

$button-font-size: $size-normal;
$button-font-weight: $weight-normal;

$button-small-height: 3.2rem;
$button-small-padding-horizontal: $button-padding-horizontal;
$button-large-height: 4.8rem;
$button-large-padding-horizontal: $button-padding-horizontal;
// --

// = components
// $modal-card-title-font-weight: $weight-bold;
// $modal-small-width: 320px;
// $modal-content-width: 640px; // normal-width (bulma var)
// $modal-medium-width: 780px;
// $modal-large-width: 960px;
// $modal-full-width: 100%;
// $modal-full-height: 100%;

$navbar-dropdown-item-color: $text;

// $tabs-link-background-color: $scheme-main-bis;

// $tabs-has-box-shadow-box: -2px 0px 0px -1px rgba(10, 10, 10, 0.1);

// $tabs-sheet-link-background-color: $scheme-main-bis;
// $tabs-sheet-link-border-color: $border;
// $tabs-sheet-link-border-style: solid;
// $tabs-sheet-link-border-width: 1px;
// $tabs-sheet-link-hover-background-color: $background;
// $tabs-sheet-link-hover-border-color: $border-hover;
// $tabs-sheet-link-active-color: $primary;
// $tabs-sheet-link-active-background-color: $scheme-main;
// $tabs-sheet-link-active-border-color: $border;
// $tabs-sheet-link-active-border-bottom-color: transparent;

// $tab-highlight-border-color: $primary;
// $tab-highlight-border-style: solid;
// $tab-highlight-border-width: 3px;
// --

// = forms
$checkbox-size: 1em;
$checkbox-inner-size: $checkbox-size * 0.8;
// $checkbox-checked-color: $primary;
// $checkbox-checked-border-color: $primary;
// $checkbox-checked-filled-background-color: $primary;
// $checkbox-group-border-color: $border;
// $checkbox-group-divide-color: $border;
// $checkbox-title-filled-background-color: $primary;
// $checkbox-title-filled-color: $primary-invert;

// $radio-border-color: $primary;
// $radio-background-color: $primary;
$radio-size: 1em;
// $radio-inner-size: $radio-size - 0.5em;
// $radio-bg-size: $radio-size + 0.5;
// --

// = validations
// $required-color: $danger;
// $validation-error-border: 1px solid $danger;
// $error-message-color: $text-invert;
// $error-message-background-color: $danger;
// --

// = lib
// $completer-selected-row-background-color: $primary;
// $completer-selected-row-color: $primary-invert;

// $ngx-datatable-table-shadow: none;
// $ngx-datatable-table-border: 1px solid $border;
// $ngx-datatable-table-row-border: none;
// $ngx-datatable-table-cell-border: 1px solid $border;

$ngx-datatable-color: $text;
$ngx-datatable-font-size: $size-normal;

// $ngx-datatable-row-background-color: $scheme-main;
// $ngx-datatable-row-hover-background-color: $scheme-main-ter;
// $ngx-datatable-striped-row-even-background-color: $scheme-main-bis;
// $ngx-datatable-striped-row-even-hover-background-color: $scheme-main-ter;
// $ngx-datatable-row-detail-background-color: $background;

$ngx-datatable-table-head-cell-align: center;
$ngx-datatable-table-head-cell-color: $table-header-invert;
$ngx-datatable-table-head-cell-background-color: $table-header;
$ngx-datatable-table-head-cell-font-size: $size-normal;
$ngx-datatable-table-head-cell-font-weight: $weight-bold;

// $ngx-datatable-header-filter-wrapper-background-color: $scheme-main-bis;
$ngx-datatable-header-filter-height: $control-height;
$ngx-datatable-header-filter-radius: 0;
// $ngx-datatable-header-filter-border-color: $scheme-main-ter;
$ngx-datatable-header-filter-wrapper-padding: $brick-spacing;
$ngx-datatable-header-filter-wrapper-height: $ngx-datatable-header-filter-height + $ngx-datatable-header-filter-wrapper-padding * 2;

// $ngx-datatable-foot-background-color: $scheme-main-bis;
// $ngx-datatable-pager-color: $primary-invert;
// $ngx-datatable-pager-background-color: $primary;

// $ngx-datatable-selection-background-color: $grey;
// $ngx-datatable-selection-color: $text-invert;
$ngx-datatable-selection-checkbox-checked-filled-style: false;

// $ngx-datatable-summary-row-color: $grey;
// $ngx-datatable-summary-row-background-color: $text-invert;

// $tooltip-font-size: $size-normal;
// $tooltip-font-weight: $weight-normal;
// $tooltip-background-color: $scheme-main-ter;
// $tooltip-background-color-invert: $scheme-invert-ter;
// $tooltip-color: $text;
// $tooltip-color-invert: $text-invert;
// $tooltip-border-color: rgba(0, 8, 16, 0.15);
// $tooltip-box-shadow: 0 4px 14px -2px rgba(0, 8 , 16, .08);
// $tooltip-border-radius: 4px;
// $tooltip-arrow-size: 16px;

// --
