//-- Temporary custom styles that related to Soft-Ngx, and remove when Soft-Ngx is updated. --//

.section {
  @include mobile {
    &.is-fit {
      padding-left: $section-gap-mobile;
      padding-right: $section-gap-mobile;
    }
  }
}

.toast-container .ngx-toastr {
  .toast-close-button {
    position: static;
    text-shadow: none;
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      .toast-close-button {
        color: $color-invert;
      }
    }
  }
}

html.is-overflow-hidden {
  padding-right: 17px;
}