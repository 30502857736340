//-- Custom global styles that not fit to component base --//

// custom video theme
.vjs-theme-fantasy {
  --vjs-theme-fantasy--primary: #{$primary};
  --vjs-theme-fantasy--secondary: #fff;
}

.vjs-live-display {
  align-self: center;
}

.vjs-seek-to-live-control {
  align-items: center;
}

.vjs-liveui .vjs-marker {
  display: none;
}

.video-js .vjs-play-progress:before {
  z-index: 101; // to above .vjs-marker (z-index: 100)
}

.vjs-marker-watched {
  position: absolute;
  bottom: 0;
  height: 100%;
  z-index: 99; // to under .vjs-marker (z-index: 100)
  background-color: darken($primary, 15%);
}

.vjs-progress-control.vjs-control {
  margin-right: 0.5em;
}

.vjs-playback-rate-value {
  display: flex;
  justify-content: center;
  align-items: center;
}

// start trunks ui = sidebar-fluid-layout.scss
#mainContainer {
  display: flex;

  .container {
    max-width: none !important;
    width: 100% !important;
  }
}

#sidebar {
  width: auto;
}

#content {
  flex: 1;
}

// end trunks ui = sidebar-fluid-layout.scss

// fix sidebar & glide.js need actual width
#content {
  width: calc(100% - #{$sidebar-width});
}

@include tablet {
  body.mini-bar {
    #content {
      width: calc(100% - #{$mini-bar-width});
    }

    .section {
      padding-left: $section-gap-mini-bar;
      padding-right: $section-gap-mini-bar;
    }
  }
}


.ng-sidebar__content {
  overflow: visible !important;
  position: static !important;
  // min-height: 100vh;
}

.ng-sidebar__backdrop {
  z-index: 2 !important;
}

._sidebar-opened-content {
  height: 100vh;
}

._g-watermark {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.05;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  ._g-watermark-text {
    background-color: black;
    font-size: 4.9rem;
    color: white;
    transform: rotate(-45deg);
    white-space: nowrap;
    padding: 0 0.5em;
  }
}

._g-tabs {
  white-space: nowrap;
}

._g-tabs.tabs {
  &.is-toggle {
    li {
      a {
        display: block;
        border-color: $primary;
        color: $primary;
        text-align: center;
        min-width: 60px;
      }

      &.is-active {
        a {
          background-color: $primary;
          color: $primary-invert;
          font-weight: $weight-bold;
        }
      }

      span {
        display: block;
        margin: 0;
      }
    }
  }
}

._g-course-cover-image {
  padding-top: $course-cover-image-padding-top-percent;
  background-image: url('~src/assets/image/course.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

._g-short-answer-control {
  display: inline-block;
  width: 100px;
  vertical-align: middle;
}

._g-circle-number {
  border-radius: 50%;
  background-color: $secondary;
  color: $secondary-invert;
  width: 2.8rem;
  height: 2.8rem;
  line-height: 2.8rem;
  text-align: center;
  font-size: $size-small;
  display: inline-block;
}

._g-user-profile-level {
  padding: 0px 4px;
  font-size: $size-small;
  font-weight: $weight-bold;
  color: white;
  border-radius: 2px;
  background: $background;
}

.fr-toolbar {
  border-top: 0;
}

// Froala audio
.fr-audio {
  text-align: left;
}

audio {
  height: 54px;
}


// Ngx-Datatable
// workaround to work with sidebar collapsable/expandable
.datatable-scroll {
  width: auto !important;
}

// glide work with .has-shadow-hovered of carousel item
._g-carousel-item-has-shadow-hovered {
  .glide__slides>* {
    margin-top: 1px;
    margin-bottom: 10px;
  }

  .glide__track {
    margin-left: -7px;
    margin-right: -10px;
    padding-left: 7px;
    padding-right: 10px;
  }
}

@include touch {

  // To work with ng-sidebar
  ._sidebar-opened-content {

    #header,
    #mainContainer {
      width: 100vw;
    }
  }

  // To fix video on touch not fit page
  .vjs-control-bar {
    overflow: hidden;
  }
}

@include tablet {
  ._g-tabs.tabs {
    &.is-toggle {
      li {
        a {
          display: flex;
          padding: 0.75em 1.5em;
        }

        span {
          display: inline;

          &:first-child:not(:last-child) {
            margin-right: 0.5em;
          }
        }

      }
    }

    &.is-fullwidth {
      li {
        flex-grow: 0;
      }
    }
  }
}