@import "~trunks-ui/utilities/functions";
@import "variables-theme";

// Square Size, Brick & Block Spacing
$square-size: 0.8rem;
$brick-spacing: $square-size;
$block-spacing: $square-size * 2;
$block-spacing-mobile: $block-spacing;

// Control height
$control-height: 4rem; // unit must same as Font size
$control-line-height: 1.5;

// Font size
$size-xsmall: 1.1rem;
$size-small: 1.2rem;
$size-normal: 1.4rem;
$size-medium: 1.6rem;
$size-large: 1.8rem;
$size-xlarge: 2.2rem;
$size-xxlarge: 3rem;

$font-sizes: (
  "size-xsmall": $size-xsmall,
  "size-small": $size-small,
  "size-normal": $size-normal,
  "size-medium": $size-medium,
  "size-large": $size-large,
  "size-xlarge": $size-xlarge,
  "size-xxlarge": $size-xxlarge
);

// Weight
$weight-normal: 400;
$weight-bold: 700;

$font-weights: (
  "weight-normal": $weight-normal,
  "weight-bold": $weight-bold
);

// Miscellaneous
$easing: ease-out;
$radius-small: 2px;
$radius: 4px;
$radius-large: 6px;
$radius-rounded: 290486px;
$speed: 86ms;

// Color schemes for light/dark mode switching
$darkMode: false;

// General colors
$scheme-main:       #ffffff;
$scheme-main-bis:   #fafafa;
$scheme-main-ter:   #f2f2f2;
$scheme-invert:     #000000;
$scheme-invert-bis: #121212;
$scheme-invert-ter: #242424;

@if $darkMode {
  $scheme-main:       #000000;
  $scheme-main-bis:   #121212;
  $scheme-main-ter:   #242424;
  $scheme-invert:     #ffffff;
  $scheme-invert-bis: #fafafa;
  $scheme-invert-ter: #f2f2f2;
}

// Shades of grey between color schemes above
$grey-darker:       #3E3E3E;
$grey-dark:         #4a4a4a;
$grey:              #797979;
$grey-light:        #AAAAAA;
$grey-lighter:      #dddddd;
$grey-lightest:     #ededed;

// Text colors
$text-strong:       #3E3E3E;
$text:              #31422f;
$text-light:        #696e69;
$text-lighter:      #d9e4d9;
$text-disabled:     #AAAAAA;
$text-placeholder:  #999999;
$text-disabled-placeholder: #d2d2d2; // ~ rgba($text-light, 0.3)

// Border colors
$border-hover:      #404040;
$border-light-hover:#E5E5E5;
$border:            #E5E5E5;
$border-light:      #E5E5E5;
$border-lighter:    #E5E5E5;

// A general background color. Mostly used for divider, hovered menu, disabled input
$background:        #F4F4F4;

// Text invert color
$text-invert:       #ffffff;


@function findColorInvert($color) {
  @if (colorLuminance($color) > 0.55) {
    @return $text-strong;
  }
  @else {
    @return $text-invert;
  }
}

// Theme colors
$info:          #0c495c;
$success:       #679466;
$warning:       #F7B505;
$danger:        #D13F5C;
$base:          #414A5B;

// Link colors
$link-invert: findColorInvert($link);

// Custom colors
$home-section-background:   #FFFFFF;
$home-section-background-colored: #EDF6FC;
$slider-next:               #AAAAAA;
$chapter-inner-background:  #E5E5E5;
$question-background:       #797979;
$roadmap-table-header:      #9BD2F7;
$roadmap-table-header-focus:#2699FB;
$content-header:            #231F20;
$badge-list-bg:             #305029;
$level-box-background-color:#2098c3;
$banner-background-color:   #eef0eb;

$status-completed: #6eba1e;
$status-InProgress: #2098c3;
$status-NotStarted: #9a9a9a;

// Custom colors with class
$progress-NotStartedYet:#FDDA7B;
$progress-Upcomming:    #CACACA;
$progress-Overdue:      #FF7B7B;
$progress-InProgress:   #8BD7F8;
$progress-Completed:    #44CBB6;

$progress-Passed: #44CBB6;
$progress-Failed: #FF7B7B;
$progress-Missed: #CACACA;
$progress-Waiting:#FDDA7B;

$progress-course-NotStartedYet: #ecb920;
$progress-course-InProgress:    #2098c3;
$progress-course-Completed:     #6eba1e;

$table-header:    #848EA0;
$table-header-invert: findColorInvert($table-header);
$exam-header:     #797979;
$reward-point:    #ecb920;
$exp-point:       #4493CB;
$got-reward:      #AAAAAA;
$question-user:   #9BD2F7;
$question-lecture:#8BD7F8;

$correct-item:#2699FB;
$wrong-item:  #CE0029;

$white:   #ffffff; // todo: remove

$custom-colors: (
  'white': ($white),
  'progress-NotStartedYet': ($progress-NotStartedYet),
  'progress-Upcomming': ($progress-Upcomming),
  'progress-Overdue': ($progress-Overdue),
  'progress-InProgress': ($progress-InProgress),
  'progress-Completed': ($progress-Completed),
  'progress-course-NotStartedYet': ($progress-course-NotStartedYet),
  'progress-course-InProgress': ($progress-course-InProgress),
  'progress-course-Completed': ($progress-course-Completed),
  'progress-Passed': ($progress-Passed),
  'progress-Failed': ($progress-Failed),
  'progress-Missed': ($progress-Missed),
  'progress-Waiting': ($progress-Waiting),
  'exam-header': ($exam-header),
  'reward-point': ($reward-point),
  'exp-point': ($exp-point),
  'got-reward': ($got-reward),
  'correct-item': ($correct-item),
  'wrong-item': ($wrong-item),
  'table-header': ($table-header),
  'hero-header': ($hero-header)
);


// Scrollbar width
$vertical-scrollbar-width: 17px;

$sidebar-width: 240px;

// Responsive Breakpoint
// mobile up to 767px;
$tablet: 768px; // for 768 = ipad portrait, for 1024 = ipad landscape = ipad pro portrait
// desktop + sidebar start at 1264
$desktop: 1024px + $sidebar-width; // for 1280, 1366 = ipad pro landscape, 1440
$widescreen: 1580px;  // for 1600
$fullhd: 1900px; // for 1920, 2560
// add each breakpoint ~ 320px

// navbar
$navbar-breakpoint: $desktop;

// Others
$mini-bar-width: 50px;
$header-height: 64px;
$course-cover-image-width: 16;
$course-cover-image-height: 9;
$course-cover-image-ratio: $course-cover-image-width / $course-cover-image-height;
$course-cover-image-padding-top-percent: percentage($course-cover-image-height / $course-cover-image-width);

@import "~trunks-ui/utilities/variables";
@import "~trunks-ui/utilities/mixins";

@mixin mobile-xs {
  @media only screen and (max-width: 370px) {
    @content;
  }
}
