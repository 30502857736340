@font-face {
  font-family: 'icomoon';
  src:
    url('../assets/font/icomoon.woff2?77s2xb') format('woff2'),  
    url('../assets/font/icomoon.ttf?77s2xb') format('truetype'),
    url('../assets/font/icomoon.woff?77s2xb') format('woff'),
    url('../assets/font/icomoon.svg?77s2xb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-announcement:before {
  content: "\e907";
}
.ic-eye-slash:before {
  content: "\e97b";
}
.ic-pdf-file:before {
  content: "\e908";
}
.ic-link:before {
  content: "\e909";
}
.ic-background-job:before {
  content: "\e906";
}
.ic-running:before {
  content: "\e920";
}
.ic-info:before {
  content: "\e905";
}
.ic-clone:before {
  content: "\e904";
}
.ic-show:before {
  content: "\e902";
}
.ic-hide:before {
  content: "\e903";
}
.ic-no:before {
  content: "\e900";
}
.ic-yes:before {
  content: "\e901";
}
.ic-circle-down:before {
  content: "\e977";
}
.ic-circle-right:before {
  content: "\e976";
}
.ic-status_not-started:before {
  content: "\e972";
}
.ic-status_in-progress:before {
  content: "\e973";
}
.ic-hr:before {
  content: "\e974";
}
.ic-export:before {
  content: "\e971";
}
.ic-image:before {
  content: "\e92b";
}
.ic-payment:before {
  content: "\e92c";
}
.ic-payment-waiting:before {
  content: "\e92d";
}
.ic-assignment:before {
  content: "\e96f";
}
.ic-exam:before {
  content: "\e92e";
}
.ic-trash:before {
  content: "\e92f";
}
.ic-attachment:before {
  content: "\e930";
}
.ic-confirm-payment:before {
  content: "\e931";
}
.ic-edit-square:before {
  content: "\e932";
}
.ic-close-cross:before {
  content: "\e93f";
}
.ic-alert:before {
  content: "\e965";
}
.ic-badges:before {
  content: "\e966";
}
.ic-ranking:before {
  content: "\e939";
}
.ic-reports:before {
  content: "\e93a";
}
.ic-roadmap:before {
  content: "\e93b";
}
.ic-certification:before {
  content: "\e93c";
}
.ic-my-course:before {
  content: "\e93d";
}
.ic-all-courses:before {
  content: "\e93e";
}
.ic-hamburger:before {
  content: "\e947";
}
.ic-previous:before {
  content: "\e933";
}
.ic-q_true-false:before {
  content: "\e934";
}
.ic-star-cup-svgrepo-com:before {
  content: "\e935";
}
.ic-video:before {
  content: "\e967";
}
.ic-circle:before {
  content: "\e968";
}
.ic-grade:before {
  content: "\e955";
}
.ic-book:before {
  content: "\e957";
}
.ic-calendar:before {
  content: "\e936";
}
.ic-clock-o:before {
  content: "\e937";
}
.ic-close:before {
  content: "\e940";
}
.ic-download:before {
  content: "\e970";
}
.ic-eye1:before {
  content: "\e943";
}
.ic-file-o:before {
  content: "\e944";
}
.ic-gear:before {
  content: "\e945";
}
.ic-list-ul:before {
  content: "\e969";
}
.ic-live_vdo:before {
  content: "\e96a";
}
.ic-next:before {
  content: "\e94b";
}
.ic-online:before {
  content: "\e94c";
}
.ic-pass:before {
  content: "\e94d";
}
.ic-photo:before {
  content: "\e950";
}
.ic-play-circle:before {
  content: "\e951";
}
.ic-plus:before {
  content: "\e959";
}
.ic-q_matching:before {
  content: "\e952";
}
.ic-q_multiple_choice:before {
  content: "\e938";
}
.ic-q_short_answer:before {
  content: "\e941";
}
.ic-q_subjective:before {
  content: "\e946";
}
.ic-q_true_false:before {
  content: "\e96b";
}
.ic-question-circle:before {
  content: "\e96c";
}
.ic-recent:before {
  content: "\e948";
}
.ic-recommend:before {
  content: "\e949";
}
.ic-reward:before {
  content: "\e94a";
}
.ic-search:before {
  content: "\e94e";
}
.ic-tag:before {
  content: "\e94f";
}
.ic-user1:before {
  content: "\e953";
}
.ic-vdo:before {
  content: "\e975";
}
.ic-volume-up:before {
  content: "\e958";
}
.ic-waiting:before {
  content: "\e95a";
}
.ic-warning:before {
  content: "\e95b";
}
.ic-x:before {
  content: "\e96d";
}
.ic-zoom:before {
  content: "\e96e";
}
.ic-all_courses:before {
  content: "\e954";
}
.ic-continue:before {
  content: "\e95c";
}
.ic-home:before {
  content: "\e95d";
}
.ic-user:before {
  content: "\e95e";
}
.ic-file_html:before {
  content: "\e95f";
}
.ic-tick:before {
  content: "\e960";
}
.ic-circle-inprogress:before {
  content: "\e962";
}
.ic-point:before {
  content: "\e963";
}
.ic-star2:before {
  content: "\e964";
}
