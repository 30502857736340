@mixin retina2x {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 240dpi) {
    @content;
  }
}

._t-cover {
  background-image: url('./oliv-bg.png');
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

._t-banner {
  background-image: url("./oliv-banner.png");
}

// ._t-login-logo-1 {
//   background-image: url("./ais.png");
//   background-repeat: no-repeat;
//   background-size: contain;
//   width: 120px;
//   height: 80px;
//   margin-left: -20px;
//   margin-right: 20px;

//   @include retina2x {
//     background-image: url("./ais@2x.png");
//   }
// }

._t-login-logo-1 {
  display: none;
}

._t-login-logo-2 {
  background-image: url("./oliv-logo.svg");
  background-repeat: no-repeat;
  width: 160px;
  height: 80px;
}

// ._t-header-logo-1 {
//   background-image: url("./ais-header.png");
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position-y: center;
//   width: 60px;
//   height: 40px;
//   margin-right: 16px;

//   @include retina2x {
//     background-image: url("./ais-header@2x.png");
//   }
// }

._t-header-logo-1 {
  display: none;
}